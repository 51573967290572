<div class="nav-backdrop" *ngIf="isOpen" (click)="closeNav()"></div>
<div class="mobile-nav-panel">
    <div class="nav-logo d-flex align-items-center justify-content-between">
        <logo [height]="70" [white]= "color === 'dark'"></logo>
        <div class="cursor-pointer" (click)="closeNav()">
            <i class="feather icon-arrow-left font-size-lg"></i>
        </div>
    </div>
    <perfect-scrollbar class="mobile-nav-panel-content">
        <vertical-menu-content (onNavLinkClick)="onClicked()"></vertical-menu-content>
    </perfect-scrollbar>
</div>