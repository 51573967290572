<div class="header-nav-item-select" (click)="openQuickPanel(template)">
    <div class="toggle-wrapper">
        <i class="nav-icon feather icon-settings"></i>
    </div>
</div>
<ng-template #template>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Configuración del tema</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <theme-configurator></theme-configurator>
    </div>
</ng-template>