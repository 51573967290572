import { NgModule } from '@angular/core';

import { SwitchComponent } from './switch.component';

@NgModule({
    imports: [],
    exports: [SwitchComponent],
    declarations: [SwitchComponent],
    providers: [],
})
export class SwitchModule { }
